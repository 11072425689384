import React, {
    useCallback,
    useMemo,
    useState
} from "react";
import {
    Button,
    OverlayTrigger,
    Tooltip
} from "react-bootstrap";

import NumberFormatter from "../../../../../components/formatters/NumberFormatter";
import DateFormatter from "../../../../../components/formatters/DateFormatter";
import EnergyMeterHistoryModal from "../EnergyMeterHistoryModal";
import DeviceIcon from "../../../../../components/DeviceIcon";
import DeviceCardRow from "./components/DeviceCardRow";
import Device3PhaseData from "./components/Device3PhaseData";

export function InstallationEnergyMeterCard({ energyMeter, onClickAddDevice, allowHistory, disabled }) {
    const [showHistoryModal, setShowHistoryModal] = useState(false);
    const [showPerPhasePower, setShowPerPhasePower] = useState(false);

    const handleCloseHistoryModal = useCallback(() => {
        setShowHistoryModal(false);
    }, []);

    const togglePerPhasePower = useCallback(() => {
        setShowPerPhasePower((prevState) => !prevState);
    }, []);

    const perPhasePowerAvailable = useMemo(() => {
        return energyMeter.powerAcL1 !== null && energyMeter.powerAcL2 !== null && energyMeter.powerAcL3 !== null;
    }, [energyMeter]);

    return (
        <div className="card mb-3">
            { allowHistory && (
                <EnergyMeterHistoryModal
                    energyMeter={ energyMeter }
                    show={ showHistoryModal }
                    handleClose={ handleCloseHistoryModal }
                />
            )}
            <div className="card-body">
                <div className="d-flex flex-row align-items-start">
                    <div className="flex-grow-1">
                        <h5 className="card-title">
                            { energyMeter.manufacturer }{" "}
                            <br className="d-md-none"/>
                            { energyMeter.model }
                            <div className="text-muted mt-1" style={{ fontSize: "0.85rem" }}>
                                { energyMeter.serialNumber }
                            </div>
                        </h5>
                    </div>
                    <div className="ml-3 d-flex flex-row align-items-center">
                        { allowHistory && (
                            <OverlayTrigger overlay={
                                <Tooltip id={ `energyMeter-history-${energyMeter.id}` }>
                                    Bekijk historie
                                </Tooltip>
                            }>
                                <Button
                                    variant="link"
                                    className="py-0"
                                    onClick={ () => setShowHistoryModal(true) }
                                >
                                    <i className="fa-solid fa-history fa-fw"/>
                                </Button>
                            </OverlayTrigger>
                        )}
                        <DeviceIcon deviceType="energyMeter"/>
                    </div>
                </div>
                <DeviceCardRow title="Functie">
                    { energyMeter.options !== null ? (
                        <React.Fragment>
                            { energyMeter.options }
                        </React.Fragment>
                    ) : "Unknown" }
                </DeviceCardRow>
                { (energyMeter.options === "Export+Import" || energyMeter.options === "Import+Export") && (
                    <DeviceCardRow title="Huidige richting">
                        { energyMeter.powerAc !== null ? (
                            energyMeter.powerAc === 0 ? (
                                "Standby"
                            ) : energyMeter.powerAc > 0 ? (
                                "Export"
                            ) : energyMeter.powerAc < 0 ? (
                                "Import"
                            ) : "Unknown"
                        ) : "Unknown" }
                    </DeviceCardRow>
                )}
                <DeviceCardRow title="Vermogen">
                    { energyMeter.powerAc !== null ? (
                        <div
                            onClick={ perPhasePowerAvailable ? togglePerPhasePower : undefined }
                            style={{ cursor: perPhasePowerAvailable ? "pointer" : undefined }}
                        >
                            { (perPhasePowerAvailable && showPerPhasePower) ? (
                                <Device3PhaseData
                                    l1={ energyMeter.powerAcL1 }
                                    l2={ energyMeter.powerAcL2 }
                                    l3={ energyMeter.powerAcL3 }
                                    unit="W"
                                />
                            ) : (
                                <React.Fragment>
                                    <NumberFormatter
                                        number={ energyMeter.powerAc }
                                        maximumFractionDigits={ 1 }
                                    /> W
                                </React.Fragment>
                            )}
                        </div>
                    ) : "Unknown" }
                </DeviceCardRow>
                { (energyMeter.voltageAcL1 || energyMeter.voltageAcL2 || energyMeter.voltageAcL3) ? (
                    <DeviceCardRow title="AC Spanning">
                        <Device3PhaseData
                            l1={ energyMeter.voltageAcL1 > 50 ? energyMeter.voltageAcL1 : null }
                            l2={ energyMeter.voltageAcL2 > 50 ? energyMeter.voltageAcL2 : null }
                            l3={ energyMeter.voltageAcL3 > 50 ? energyMeter.voltageAcL3 : null }
                            unit="V"
                        />
                    </DeviceCardRow>
                ) : null }
                <DeviceCardRow title="Laatst gezien">
                    <DateFormatter date={ energyMeter.lastSeenDate }/>
                </DeviceCardRow>
                { onClickAddDevice && energyMeter.installationId === null && (
                    <div className="d-flex justify-content-end mt-2">
                        <Button
                            variant="primary"
                            size="sm"
                            onClick={ () => onClickAddDevice("energyMeter", energyMeter.id) }
                            disabled={ disabled }
                        >
                            <i className="fa-solid fa-plus mr-2"/>
                            Energiemeter toevoegen
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
}

function InstallationEnergyMeterCards({ energyMeters, onClickAddDevice, disabled }) {
    return energyMeters.map((energyMeter) => (
        <InstallationEnergyMeterCard
            key={ energyMeter.id }
            energyMeter={ energyMeter }
            onClickAddDevice={ onClickAddDevice }
            disabled={ disabled }
        />
    ));
}

export default React.memo(InstallationEnergyMeterCards);
