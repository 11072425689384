import React from "react";
import {
    Link
} from "react-router-dom";
import {
    Alert
} from "react-bootstrap";

import InstallationTypeIcon from "../../../components/InstallationTypeIcon";
import EuroFormatter from "../../../components/formatters/EuroFormatter";
import EnergyHandlingChart from "../../installations/detail/components/EnergyHandlingChart";
import Loading from "../../../components/Loading";

function EnergyHandlingModesRow({ installation, error, energyHandlingModes }) {
    return (
        <tr className="table-clickable-row">
            <td className="align-middle text-center">
                <Link to={`/installation/${installation.id}`}>
                    <InstallationTypeIcon
                        type={installation.type}
                        className="text-muted"
                    />
                </Link>
            </td>
            <td className="align-middle">
                <Link to={`/installation/${installation.id}`}>
                    <b>{installation.name}</b>
                    <br/>
                    <span className="text-muted">{installation.address}, {installation.city}</span>
                </Link>
            </td>
            <td className="align-middle">
                <Link to={`/installation/${installation.id}`}>
                    <EuroFormatter price={installation.minEnergyPriceDeltaForChargeCycle}/>
                </Link>
            </td>
            <td className="align-middle">
                <Link to={`/installation/${installation.id}`}>
                    { error ? (
                        <Alert variant="danger">{ error }</Alert>
                    ) : energyHandlingModes ? (
                        energyHandlingModes.map((modesForInverter, index) => (
                            <EnergyHandlingChart
                                key={index}
                                hideLabels={index !== energyHandlingModes.length - 1}
                                modes={modesForInverter.modes}
                                unavailable={modesForInverter.modes.length === 0}
                            />
                        ))
                    ) : (
                        <Loading/>
                    )}
                </Link>
            </td>
        </tr>
    );
}

export default React.memo(EnergyHandlingModesRow);
