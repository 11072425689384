function formatNumber({ number, minimumFractionDigits = undefined, maximumFractionDigits = undefined }) {
    if(number === null || number === undefined) {
        return number;
    }
    return new Intl.NumberFormat("nl-NL", {
        minimumFractionDigits,
        maximumFractionDigits,
    }).format(number);
}

export default formatNumber;
